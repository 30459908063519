body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bt-700{
	font-weight: 700;
} 
.pb-10{
	padding-bottom: 10px;
}

.card-shadow{
	box-shadow: 2px 6px 15px 0 rgba(69,65,78,.1) !important
}

.card .card-body, .card-light .card-body{
	padding: 1.25rem;
	min-height: 258px;
}