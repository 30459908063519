body {
  font-size: 14px !important;
  color: #575962 !important;
  font-family: Lato,sans-serif !important;
}
.table {
	color: #575962 !important;
}
tr:hover {
    color: #575962 !important;
    background-color: rgba(0,0,0,.075);
}
select, input[type=search] {
    display: block;
    width: 100%;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    padding: .25rem .5rem!important;
    font-size: .875rem!important;
    line-height: 1.5;

}

.dataTables_length{ 
	width: 200px;
  float: left;
 }
.p-next  {
  margin: 0 2px;
  color: #777;
  border: 1px solid #dee2e6;
  padding: 10px;
  border-radius: 40px;
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 2px;
  cursor: pointer;
}

.p-previous  {
  margin: 0 2px;
  color: #777;
  border: 1px solid #dee2e6;
  padding: 10px;
  border-radius: 40px;
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 2px;
  cursor: pointer;
}
.paginate_button:hover{
	text-decoration: none;
}

div.dataTables_wrapper div.dataTables_info {
	float: left;
	padding-top: 0px !important;
	font-size: 14px;
  color: #575962;
  height: 40px;
}
.table-responsive {
	overflow-y: hidden;
}
span a.paginate_button.current {
    background: #006CD5;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}
span a.paginate_button.current:hover {
	background: #0075E2;
	color: #fff;
}
span a.paginate_button {
    background: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: inline-block;
    color: #333;
    text-align: center;
    line-height: 32px;
    border: 1px solid #dee2e6;
    margin: 0 2px;
    cursor: pointer;
}
span a.paginate_button:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}